@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400&family=Montserrat:ital@1&family=Space+Mono&display=swap');

.navigation {
  font-family: monospace;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

.navigation > ul {
  width: 10%;
  justify-content: center;
}

 @media screen and (min-width: 780px) {
  .full-width-img {
  /* margin: auto; */
  width: 90%;
  }
} 


.layout {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: #ffeeee62;
}

ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding-right: 5px;
  width: 530px;
}

li {
  display: inline;
  justify-self: center;
}

a,
a:hover {
  color: black;
  padding: 5px;
}

li:hover {
  color: #0a0a0ac5;
  opacity: 1;
  text-shadow: 2px 2px #00000016;
  border-radius: 3px;
}
.header {
  height:8vh;
  color: rgb(0, 0, 0);
  background-color: #fde4e49c;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.footer {
  background-color: rgba(0, 0, 0, 0);
  height:1vh;
  position:absolute; 
  width:100%; 
  bottom:0; 
}

h1 {
  /* font-family: 'M PLUS Rounded 1c', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Space Mono', monospace; */
  margin: 0px;
  padding: 0px 12px;
  text-shadow: 2px 2px #00000016;
  font-style: italic;
  -webkit-text-stroke-color: #000000;
  -webkit-text-stroke-width: 0.4px;
  color: transparent;
  
}

.h5,
h5 {
  font-size: 18px;
  margin: 8px 0px;
}
.spotify-widget {
  /* display: flex; */
  /* justify-self:; */
  /* margin: 5px; */
  /* min-width:100%; */
  /* position: absolute; */
  /* display: inline-block; */
  /* position: a bsolute; */
  padding: 3px;
  bottom: 0;
  z-index: 1;
  position: absolute;
  top: 8%;
}

.details {
  background-color: rgba(0, 0, 0, 0.5);
}

.product-slider {
  padding: 15px;
  width: 100%;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: none;

  color: transparent;
  -webkit-text-fill-color: rgba(49, 49, 49, 0.403);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #262626b4;

  color: rgb(42, 42, 42);
  font-family: monospace;
  max-width: max-content;
}

.slick-track {
  display: flex;
}
.slick-slider {
  display: flex;
  align-items: center;
  max-width: 70vw;
  /* height: 70vh; */
  margin: 0 auto;
}

.slick-slide {
  padding-right: 10px;
}

.slick-slide:last-child {
  padding-right: 0;
}
.slick-slide img {
  display: block;
}
.slick-list {
  margin: 0 15px;
}

.slick-prev {
  width: 50px;
  left: 0;
  top: 20%;
  position: absolute;
  /* height: 50px; */
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-next {
  width: 50px;
  right: 0;
  top: 20%;
  position: absolute;
  height: 50px;
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: rgb(255, 255, 255);
}
.product-actions {
  display: flex;
  justify-content: space-between;
}
/* html{ height:100%; } */

.bod {
  /* min-height:100%; */
  height: 60vh;
  font-size:larger;
  margin: 0;
  padding: 0;
}
.bod,
.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  font-size: 2em;
}

.line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* overflow: of; */
}

.container {
  height: 92vh;
  width: 100%;
}

.container-left,
.container-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  width: 50%;
  height: 100%;
  /* overflow: visible; */
}

.container-mid {
  width: 8%;
  display: flex;
  justify-content: space-around;
  /* height: 100%; */
}
.letters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bar {
  height: 100%;
  width: 17px;
  background-color: rgba(42, 180, 180, 0.178);
  transition: all;
}

.bar:hover{
  background-color: rgba(42, 180, 180, 0.929);
}

.hover > p {
 padding: 2%;
} 

p, .bar {
  transition: all 140ms ease-in 11s ;
}

p:hover,
.bar:hover{
  transition-delay: -110ms;
}

p {
  display: flex;
  justify-content: center;
  width: 26px;
  color: transparent;
  margin-bottom: 0;
  color: rgba(251, 251, 251, 0.078);
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #35353553;
  /* transition: all 90ms ease-out 10s ; */
}

p:hover{
  color: rgb(27, 26, 26);
  /* transition-delay: -90ms; */
}

.hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 10px;
  
}
